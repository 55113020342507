.imgWrap {
    margin-bottom: 1rem;
}	
.imgWrap > img {
        width: 100%;
    }

.chat-card {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    border: 2px solid #04B6F529;
    background: rgba(4, 182, 245, 0.08);
    font-size: 1rem;
}	
.chat-card .chat-card__title {
        font-size: 1rem;
		line-height: 1.25rem;
        margin-bottom: 0.4rem;
    }
.chat-card .chat-card__description {
        font-size: .9rem;
		line-height: 1.2rem;
        margin-bottom: 0.5rem;
}

.msg-card .call-card__title {
	font-size: 1rem;
	line-height: 1.25rem;
	margin-bottom: .4rem;
	/*font-weight: 400;*/
	margin-left: .5rem;
}

/*.msg-card .femida-btn {
  border-radius: .4rem;
}/**/
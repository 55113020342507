@keyframes fadeInLoading {
    to { opacity: 1; }
}


.loading-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    pointer-events: none;
    opacity: 0;
    animation: fadeInLoading .2s linear forwards;
    animation-delay: 1s;
}

.loading-screen .spinner-border {
    color: #04B6F5; 
    width: 4rem;
    height: 4rem;
}
.header-search-bar {
  position: relative;
  padding: 0;
  margin: 0;
  margin-top: 1px;
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
  outline: unset;
}
.search-div  {
	max-height: 40px;
	border: 1px solid #e9e9e9;
	box-shadow: rgba(99,99,99,.03) 0 2px 8px 0;
	margin:0;

	position:relative;
	padding:10px 5px 10px 30px;
	background:#fff;
	border-radius:8px;
	max-width:100%;
	width:100%;
	min-width: 285px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	outline: unset;
}
.mainpage-wrapper .search-div  {
    min-width: auto !important;
}

.search-div img,  .search-div input[type="image"] {
	position:absolute;
	left:0;
	width:20px;
	margin:10px;
	padding: 0;
	box-sizing: border-box;
	outline: unset;
	top:0;
	z-index: 99999;
}
.search-div input:not([type="image"]) {
	max-width:100%;
	width:100%;
	min-width:285px;
	border:0px solid red;
	outline:none;
	padding:5px 5px 5px 5px;
	color: #212529;
	margin:0;
	box-sizing: border-box;
	border-radius: 0;
	font-weight: 400;
	line-height: 1.7;
}
.mainpage-wrapper .search-div input:not([type="image"]) {
    min-width: auto !important;
}

.mainpage-header .search-bar-wrap{
	margin-left: 10px;
}
.search-div input::placeholder {
  color: #999999;
  font-weight: 400;
  opacity:1
}
.search-div input:-ms-input-placeholder {
  color:#999999;
}
.search-div input::-ms-input-placeholder {
  color:#999999;
}

#search-bar-header-page {
	z-index: 10;
	margin-top: 0;
	/*display: flex;*/
	display: none;
	align-items: center;
	box-sizing: border-box;
	outline: unset;

	padding: 10px;
	position: absolute;
	background: #fff;
	border: 1px solid #e4edfb;
	left: 50%;
	transform: translateX(-50%);
	gap: 10px;
	justify-content: start;
	align-items: center;
	border-radius: 5px;
	/*! margin-top: -15px; */
	overflow-y: auto;
	max-width: 100%;
	width: 100%;
	min-width: 200px;
	flex-direction: column;
	max-height: 400px;
	box-shadow: rgba(100,100,111,.2) 0 7px 29px 0;
}
#search-bar-header-page.ds-none {display: none !important;}
#search-bar-header-page.ds-flex {display: flex !important;}
.noresult {
	font-size: 16px;
	color: #070707;
	width: 100%;
	margin: 0;
	padding: 10px;
	box-sizing: border-box;
	outline: unset;
}
.search-bar-home-page div {
	padding: 6px 10px;
	border: 1px solid #eee;
	width: 100%;
	/*background: #f2f7ff;
	border-radius: 5px;*/
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	background: #fff;
	padding: 4px 1em 4px .4em;
	/*border-bottom: 0;*/ /*solid 1px #ccc;*/
	cursor: pointer;
	font-size: .9rem;
	line-height: 1.4;  
}
.search-bar-home-page div:hover {
  background: #eee;
  border: 1px solid #ccc;
}



/*

div.search-wrapper {
	width: 100%;
    padding-right: 0rem !important;
    padding-left: 1rem !important;

	position: relative;
	border-radius: 1rem !important;
}

div.search-wrapper form.uk-search {width: 97% !important; }
div.search-wrapper form.uk-search .header-search-input {
    border: none;
    outline: none;
	line-height: 1.7;
    width: 84%;
    overflow: hidden;	
}

div.search-wrapper form.uk-search .header-search-input::placeholder {
    font-weight: normal;
	color: #eee;
}

.header-search-img {padding: 0 12px 0 0;}

div.search-wrapper form.uk-search .search_autocomplete div {
  background: #fff;
  padding: 4px 1em 4px .4em;
  border-bottom: 0; 
  cursor: pointer;
}

div.search-wrapper form.uk-search .search_autocomplete div:hover {
  background: #eee;
  border: 1px solid #ccc;
}



div.search-wrapper form.uk-search .search_autocomplete {
	background: #fff;
	font-size: .9rem;
	line-height: 1.4;
	margin-left: -1rem;
	margin-top: -4px;
	max-height: 500px;
	overflow: hidden;
	padding-top: 10px;
	position: absolute;
	width: 100%;
	z-index: 9999999;

}
div.search-wrapper form.uk-search .search_autocomplete:not(:empty) {
  border: 0px solid #eee;
}*/
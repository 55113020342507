.container {
    flex-basis: 100%;
    min-width: 100%;
    margin: 0 -16px;
    padding: 8px;
    display: flex;
    align-items: center;
}

.imgPreview {
    height: 90px;
    width: 90px;
    border-radius: 4px;
    background: center center no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

.shadow {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    display: grid;
    place-items: center;
}
$primary: #04B6F5;
.fillBlue {
    height: 40px;
    width: 40px;
    & > path {
        fill: $primary;
    }
}

.semibold {
    font-weight: 600;
}
$primary: #04B6F5;

.yourMsg, .theirMsg {
    max-width: 95%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: fit-content;
}


.yourMsg {
    align-items: flex-end;
    align-self: flex-end;

    & .yourContent {
        border-radius: 1rem 0 1rem 1rem;
        background-color: $primary;
        padding: 8px 12px;
        color: white;
        overflow-wrap: break-word;
        max-width: 100%;

        & .imgWrap {
            border-radius: 1rem 0 1rem 1rem;
        }
    }
}

.theirContent {
    border-radius: 0 1rem 1rem 1rem;
    background-color: #fff;
    padding: 8px 12px;

    & .imgWrap {
        border-radius: 0 1rem 1rem 1rem;
    }
}

.imgWrap {
    margin: -8px -12px;
    overflow: hidden;
    min-width: 50%;

    & > img {
        width: 100%;
        height: auto;
    }
}
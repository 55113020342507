.category-card-wrapper {
    float:left;
}

.category-card {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: black;
    text-decoration: none;
    padding: 10px;
    border-radius: 50%;
    background-color: #fff;
    border: solid 3px #60d9ff3d;
    width: 200px;
    height: 200px;
    text-align: center;
    box-shadow: 5px 4px 25px -4px #ccc;
    padding-top: 32px;

    &:hover {
        color: black;
        background-color: #eeeeee;
    }
}

.category-inner-wrapper .category-card {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: black;
    text-decoration: none;
    padding: 10px;
    border-radius: 1rem;
    background-color: #fff;
    border: unset;
    width: auto;
    height: 125px;
    text-align: left;
    box-shadow: unset;
    margin-left: auto;

    &:hover {
        color: black;
        background-color: #eeeeee;
    }
}

.category-card__description {
    font-size: 0.9rem;
}

.category-card-wrapper .category-card svg {
    margin: 0;
}

.category-services-wrapper {
    padding-top: 50px;
}

.category-card-wrapper.category-card-down {
    padding-top: 100px;
    display: flex;
    position: relative;
    justify-content: center;
}

.category-inner-wrapper .category-services-wrapper,
.category-inner-wrapper .category-card-wrapper.category-card-down
{
    padding-top: 0;
}

.category-card-wrapper.category-card-up {
    margin-top: -50px;
    display: flex;
    justify-content: center;
    position: relative;
}

.category-card-arrow-left {
    background: url("/assets/arrow_down_1.png");/**/
    height: 99px;
    position: absolute;
    right: -90px;
    top: 65px;
    -webkit-transform: rotate(70deg);
    transform: rotate(70deg);
    width: 86px;
    z-index: 9;
}

.category-card-arrow-right {
    background: url("/assets/arrow_down_2.png");/**/
    height: 99px;
    left: -90px;
    position: absolute;
    bottom: 26px;
    -webkit-transform: rotate(-70deg);
    transform: rotate(-70deg);
    width: 86px;
    z-index: 9;
}
.category-card-wrapper:last-child .category-card-arrow-right, 
.category-card-wrapper:last-child .category-card-arrow-left,
.category-inner-wrapper .category-card-arrow-right,
.category-inner-wrapper .category-card-arrow-left {
    display: none!important;
}

.category-card-wh-120 .category-card{
    height:120px;
    width:120px;
    padding-top: 10px;
}

@media (max-width: 490px){ 
    .category-card {
        height: 174px;
        width: 174px;
        padding-top: 10px;
        margin-left: -10px;
    }
}

@media (max-width: 350px){ 

    .category-card {
        height: 90vw;
        width: 90vw;
        max-height: 200px;
        max-width: 200px;
        margin: auto;
        padding-top: 32px;
    }

    div.category-list{
        display: block;
        grid-template-columns:unset;
        grid-gap:unset;
        gap:unset;
    }
    div.category-card-wrapper.category-card-down,
    div.category-card-wrapper.category-card-up{
        padding-top: 25px;
        margin: 0 auto;
        width: 100%;
    }
    .category-services-wrapper {
        padding-top: 0;
    }
    .category-card-arrow-left {
        right: 40px;
        top: 85%;
        -webkit-transform: rotate(125deg);
        transform: rotate(125deg);
    }
    .category-card-arrow-right {
        left: 45px;
        bottom: -30%;
        -webkit-transform: rotate(-125deg);
        transform: rotate(-125deg);
    }/**/
}
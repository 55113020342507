.femida-collapse__name {
    font-weight: 500;
    font-size: 18px;
    max-width: 90%;
}

.femida-collapse__control {
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.femida-collapse__arrow {
    transition: all .1s;
    transform: rotate(0deg);
    &.true {
        transform: rotate(180deg);
    }
}

.femida-collapse {
    padding: 12px 14px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
}
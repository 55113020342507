.sortTypeText {
    outline: none;
    border: none;
	border-radius: 1rem;
    padding: 10px 15px;
    background: #ECECEC;
    color: rgba(34, 34, 34, 0.34);
    transition: all .05s;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: none;   
	

    &.active {
        color: #FFFFFF;
        background: #04b6f5;
    }
}


.optionsWrap {
    display: flex;
    gap: 10px;
    padding: 7px 0;
    overflow: hidden;

    &::-webkit-scrollbar {
        visibility: hidden;
    }

    &.wrap {
        flex-wrap: wrap;
    }
}

.label {
    width: max-content;
    margin-bottom: 8px;
}
.femida-collapse__name {
    font-weight: 500;
    font-size: 1.1rem;
    max-width: 90%;
}
.femida-collapse__phones a {
    display: block;
	float: left;
	width: 100%;
}
.femida-collapse__phones a:hover {
    color: #04b6f5;
}
.femida-collapse__services span+span {margin-right: 12px; margin-left: 12px;}
.femida-collapse__services span+span:before {content: "\2022";}

.femida-collapse__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.femida-collapse__arrow {
    transition: all .1s;
    transform: rotate(0deg);
    &.true {
        transform: rotate(90deg);
    }
}
.femida-collapse {
    padding: 12px 14px;
    background-color: #fff;
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
}


.imgWrap {
    overflow: hidden;
    border-radius: 1000px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 100%;
        height: auto;
    }
}

.radioInputWrap {
    position: relative;
    height: 16px;
    width: 16px;
    
    & > svg {
        position: absolute;
        height: 16px;
        width: 16px;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all .1s;
    }

    & > input {
        height: 16px;
        width: 16px;
        position: absolute;
        height: 16px;
        width: 16px;
        top: 0;
        left: 0;

        visibility: hidden;
        &:checked {
        }

        &:checked + svg {
            opacity: 1;
        }
    }

}

.stickyTop {
    position: sticky;
    top: 0;
    background-color: #FAFAFB;
    z-index: 1;
    margin-top: -16px;
    padding-top: 16px;
    padding-bottom: 8px;
}

.searchSticky {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;

    & > svg {
        position: absolute;
        z-index: 2;
        right: 16px;
    }
}

.subjectSearch {
    position: relative;
    display: flex;
    align-items: center;

    & > svg {
        position: absolute;
        z-index: 2;
        right: 16px;
    }
}

.regionSearch {
    border-radius: 0;
    margin-top: -1px;
    padding: 8px 16px;
    background-color: #fff;
    border: none;
    outline: none;
} 
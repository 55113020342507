.article-image-c {
    overflow: hidden;
    width: 100%;
    min-width: 100vw;
    max-width: 576px;
    margin: 0 -16px;
    cursor: pointer;
    margin-top: 1.5rem;
}

.article-image {
    width: 100%;
    height: auto;
    max-width: 576px;
}

.article-image-full-c {
     position: fixed;
     top: 0;
     left: 0;
     background-color: rgba(0, 0, 0, 0.5);
     width: 100vw;
     height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
}
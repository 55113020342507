 .map-screen {
     position: fixed;
     background-color: gray;
     height: 100vh;
     width: 100vw;
     top: 0;
     left: 0;
     overflow: hidden;
     z-index: 100;
 }

 .mapImg {
     width: 100%;
     position: absolute;
 }

.map-controls {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    display: flex;
    padding: 1rem;
    gap: 10px;
    align-items: center;
    z-index: 9999;
}

.map-wrap {
    position: absolute;
}
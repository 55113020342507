.pdf-wrap {
    height: 340px;
    width: 240px;
    overflow: hidden;
    position: relative;

    & .react-pdf__message {
        position: relative;

        & .loading-screen {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center; 
        }
    }

    & > div:not(.pdf-page-view) {
        height: 340px;
        width: 240px;

        & > * {
            height: 340px !important;
            width: 240px !important;
        }
    }

    & .pdf-page-view {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;

        & .pdf-page-view__dots-list {
            display: flex;
            justify-content: center;
            gap: 12px;
            width: 100%;

            & .pdf-page-view__dot {
                height: 10px;
                width: 10px;
                border-radius: 69px;
                background-color: #ececec;
                transition: all .1s;

                &.true {
                    background-color: #04B6F5;
                }
            }
        }
    }
}
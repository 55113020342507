.badge {
    position: absolute;
    right: 0;
    top: 0;
    background: #7CDDFF;
    padding: 4px 12px;
    border-radius: 35px;
}

.listWrap {
    display: flex;
    overflow-x: scroll;
    gap: 8px;
    max-width: 100%;

    &::-webkit-scrollbar {
        visibility: hidden;
    }
}

.docWrap {
    height: 85px;
    width: 85px;
    min-width: 85px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;

    & > img {
        min-width: 100%;
        height: auto;
    }
}
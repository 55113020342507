.video, .audio {
    height: 200px;
    border-radius: 4px;
    background-color: lightgray;
    background: center center no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
     
    & span {
        color: white;
        font-size: 20px;
        font-weight: 500;
        position: absolute;
        height: 100%;
        width: 100%;
        display: grid;
        place-items: center;
        background-color: #00000080;
    }
}

.video {
    background-image: url('../../../../public/assets/call/video.jpg');
}

.audio {
    background-image: url('../../../../public/assets/call/audio.jpg');
}
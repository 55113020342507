.profile-pic-input {
    display: none;
}

.profile-edit-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(25%) translateY(25%);
}

.profile-pic-w {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 90px;
    border-radius: 8px;
    background-color: lightgray;

    & > img {
        object-fit: cover;
        height: 90px;
        width: 90px;
        border-radius: 8px;
    }
}
.card {
    background-color: #fff;
    border-radius: 16px;
    padding: 12px;
    min-width: 300px;
    max-width: 300px;
}

.imgWrap {
    height: 40px;
    width: 40px;
}
.spinner {
    color: #04B6F5; 
    width: 1.5rem;
    height: 1.5rem;
}

.smol {
    height: 20px;
    width: 20px;
    color: white;
}
.femida-collapse__name {
    font-size: 1.05rem;
    font-weight: 500;
    max-width: 90%;
}

.codex-article-link {
    /* color: #04b6f5; */
    font-size: 1rem;
    text-decoration: none;
    margin-top: 10px;
}

.codex-article-link:hover {
    color: #04b6f5;
    text-decoration: underline;
}

.codex-crumbs small {margin-right: 8px;}
.codex-crumbs small+small {margin-right: 8px; margin-left: 0;}
.codex-crumbs small+small::before {
    content: "\2022";
    margin-right: 8px;
}

.codex-crumbs small,
.codex-crumbs small a {
    color: rgba(34,34,34,.6);
}
.codex-crumbs small a {
	transition: all 0.5s ease-out;
}
.codex-crumbs small a:hover {
    color: #212529;
}


ul.RecursiveCodexCard, .RecursiveCodexCard ul {
    padding-left: 10px;
    list-style: none;
    margin-top: 19px;
}

.RecursiveCodexCard>li>span {
    font-size: 1rem; font-weight: 600;
}

.RecursiveCodexCard span {
    font-size: 1rem;
}


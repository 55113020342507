body {
    background-color: #FAFAFB;
    max-width: 576px;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
        color: inherit;
    }
}

$primary: #04B6F5;

.femida-btn {
    outline: none;
    border: none;
    background-color: $primary;
    padding: 10px 15px;
    color: white;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: none;

    transition: box-shadow .15s;

    &:hover {
        background-color: darken($color: $primary, $amount: 5%);
    }

    &:focus {
        box-shadow: 0 0 1px 3px lighten($color: $primary, $amount: 20%);
    }

    &:disabled {
        background-color: lighten($primary, 30%);
        cursor: default;
        /*&:hover { }*/
    }

    &.default {
        background-color: #2B3A4C;

        &:hover {
            background-color: darken($color: #2B3A4C, $amount: 5%);
        }
        &:focus {
            box-shadow: 0 0 1px 3px lighten($color: #2B3A4C, $amount: 20%);
        }
    }

    &.slim {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}


.call_stream_small {
    width: 25%;
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 9;
}
.call_stream_full {
    width: 100%;
    position: absolute;
    top: calc(50vh - (384px / 2));
    z-index: 9;
    width: calc(100% - 34px);
    right: 17px;
}

.call_stream_wrapper {
    height: calc(100vh - 30px);
    background: rgba(0, 0, 0, 0.4);
    top: 15px;
}

.call_stream_controls {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 99;
}

.call_stream_loader{
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
}

.femida-input {
    outline: none;
    border-radius: 100px;
    border: none;
    width: 100%;
    padding: 8px 12px;
    background-color: #2121210a;

    &.textarea {
        border-radius: 1rem;
    }
}

.femida-input-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #2b3a4c;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 1rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.femida-input-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
input.input-field__error, input.is-danger{color: #f00;border-color: #f00;}
.form-required {
    color: #f23a3c;
}
p.is-danger.help {
color: #f00;
font-size: .8rem;
padding-top: 0.2rem;
}
.femida-input__eye {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}


.category-title {
    font-size: 1.3rem;
    margin: 10px;
    font-weight: 600;
    color: black;
    text-align:center;
}

.category-title.category-title-too-mach {
    font-size: 1.1rem;
}

.femida-link {
    color: $primary;
    text-decoration: none;
   /* font-weight: 500;
    font-size: 1rem;*/
	font-size: .9rem;
	transition: all .3s;

    &:hover {
        color: darken($primary, 7%);
    }
}

.search-input {
    padding: 8px 16px;
    background-color: #fff;
    border-radius: 999px;
    border: none;
    outline: none;
    font-weight: bold;
    
    &::placeholder {
        font-weight: normal;
    }
}

.round-wrap {
    overflow: hidden;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 100%;
        height: auto;
    }
}


.search-result {
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgray;
}

.search-result-category {
    padding: 8px 1rem;
    margin: 0 -1rem;
    background: rgba(33, 33, 33, 0.04);
    font-weight: 500;
}

.mainpage-wrapper,
.mainpage-wrapper .about_wrapper h3
{font-size: 1rem; line-height:1.3;}


.mainpage-wrapper h1.category-title {font-size: 1.2rem;margin: 0px auto;line-height: 1.3;}
.mainpage-wrapper p,
.contentpage-wrapper p {
    font-size: .95rem;
	line-height:1.3;
}

/*.mainpage-wrapper .call-card {
    background: url('/static/media/img.svg') no-repeat rgba(4, 182, 245, 0.08);
}*/

.mainpage-wrapper .about_wrapper h5 {
    font-size: 1rem;
	margin-bottom: 0;
}
.mainpage-wrapper .about_wrapper .author_name h5, .mainpage-wrapper .about_wrapper .author_name small {
    font-size: .865rem;
	margin-bottom: 0;
}
.mainpage-wrapper .about_wrapper .author_desc {
    font-size: .865rem;
}
.mainpage-wrapper .about_wrapper .author_img {
    background-image: url('https://my-advo.cat/assets/images/panel/chulkov_vv.jpg'); 
	background-position: center center; 
	background-size: cover;
}
.mainpage-wrapper .about_wrapper .single_author img {
    vertical-align: baseline;
    width: 65%;
}

#form_result .formErrors,
#form_result .formErrors p {color: #f23a3c; font-size: 0.95em;}

/*#form_result .formMsg,*/
#form_result .formMsg p span {display: inline-block;}
#form_result .formMsg a{color: #04b6f5;}
#form_result .formMsg a:hover {
  filter: brightness(92%);
}

input:focus {border: inherit;}


.femida-search-btn {
    background-color: #ECECEC;
    border: none;
    border-radius: 1rem;
    box-shadow: none;
    color: #222222;
    font-size: .85rem;
    font-weight: 500;
    outline: none;
    padding: 6px 10px;
    transition: box-shadow .15s;
    margin-bottom: 5px;
}
.femida-search-btn:hover,
.femida-search-btn.active
 {
    background-color: #04b6f5;
    color: #fff;
}

.text-restricted {
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgb(255, 250, 250));
  overflow: hidden;
  position: relative;
  mix-blend-mode: hard-light;
}
.text-restricted:after {
 position: absolute;
 top: 0px;
 background: linear-gradient(transparent, gray);
 left: 0px;
 content: "";
 width: 100%;
 height: 100%;
 pointer-events: none;
}

h4:not([class]) {font-size: 1.2rem;}


.btn-exist{padding: 0;}
.btn-exist svg{width: 20px; height: 20px;padding:0;}


.lawyerspage-wrapper .femida-collapse__name {
    font-weight: 500;
    font-size: 1.1rem;
    max-width: 90%;
}
.lawyerspage-wrapper .femida-collapse__phones a {
    display: block;
	float: left;
	width: 100%;
}
.lawyerspage-wrapper .femida-collapse__phones a:hover {
    color: #04b6f5;
}
.lawyerspage-wrapper .femida-collapse__services span+span {margin-right: 8px; margin-left: 8px;}
.lawyerspage-wrapper .femida-collapse__services span+span:before {content: "\2022";margin-right: 8px;}

.lawyerspage-wrapper .femida-collapse__phones,
.lawyerspage-wrapper .femida-collapse__services 
{float: left; display: block; width: 100%; margin-top: 5px;}

.lawyerspage-wrapper .femida-collapse__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.lawyerspage-wrapper .femida-collapse__arrow {
    transition: all .1s;
    transform: rotate(0deg);
    &.true {
        transform: rotate(90deg);
    }
}
.lawyerspage-wrapper .femida-collapse {
    padding: 12px 14px;
    background-color: #fff;
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
}


.promo-trigger {
  position:fixed !important;
  right:15px;
  display:flex;
  align-items:center;
  border-radius:30px;
  box-shadow:0px 5px 7px 0px rgba(0,0,0,0.02);
  color:#fff !important;
  background:linear-gradient(16deg, rgba(4,182,245,1) 0%, rgba(245,4,13,1) 50%, rgba(38,43,62,1) 100%);
  z-index:999;
  bottom:155px;
  transform:translateY(0);
  transition-delay:0.4s;
}
.promo-trigger:hover,
.promo-trigger:focus,
.promo-trigger:active {
  color:#fff !important;
  transform: rotateY(3.142rad);
}
.promo-trigger-img {
  width:60px;
  height:60px;
  border-radius:30px;
  padding:18px;
  transition:all .4s
}

.promo-trigger img {
	max-width: 100%;
	height: auto;
}
.promo-trigger-text {
  padding:14px 0 14px 0;
  height:60px;
  width:0 !important;
  overflow:hidden;
  white-space:nowrap;
  text-transform:uppercase;
  letter-spacing:0.1em;
  font-weight:600;
  transition:all .4s;
  visibility:hidden;
  font-size:12px;
  line-height:16px;
  color:#fff !important
}
/*
.promo-trigger:hover .promo-trigger-text,
.promo-trigger:focus .promo-trigger-text,
.promo-trigger:active .promo-trigger-text
 {
  width:165px;
  visibility:visible;
  padding:14px 14px 14px 0;
}*/

.accordion-button:focus{
    box-shadow: none;
}

.accordion-button::after{
    background-color: #fff;
    border-radius: 20px;
    width: 24px;
    align-content: center;
    height: 24px;
    background-position: center;
}
.mainpage-header {
    position: sticky;
    top: 0;
    padding-top: 16px;
    /*margin-top: -16px;/**/
    margin-top: 0;
    z-index: 999999;
    background-color: #FAFAFB;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    /*padding-bottom: 16px;*/
}

.mainpage-header .search-bar-wrap {
    max-width: 88%;
}
.mainpage-header .search-bar-wrap .mb-3 {margin-bottom: 0 !important;}

.mainpage-header-title-wrapper{
    display: flex;
    flex-direction: column;
}

.mainpage-header-title-wrapper>div{
    display: block;
}

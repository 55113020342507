.my-2 {
  margin-top:.5rem!important;
  margin-bottom:.5rem!important;
}
.media {
  display: flex;
  background: #fff;
  border-radius: 8px;
  padding: 12px 35px 12px 15px;
}
.media.arrow-hover {
  position: relative;
}
.media .only-icon {
	display: inline-flex;
}

.media .only-icon img {
	width: 33px;
	align-self: baseline;
}

.media .text-primary {
  color: rgba(0,102,255,1) !important;
}
.media .arrow-icon::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	width: 12px;
	height: 12px;
	border-right: 2px solid #04b6f5;
	border-bottom: 2px solid #04b6f5;
	margin: auto;
	right: 0;
	transform: rotate(-45deg);
}
.media.arrow-hover .arrow-icon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 25px;
	margin: auto;
	transition: ease all .35s;
}
.media .arrow-icon {
	width: 32px;
	height: 12px;
	position: relative;
}
.media .text-white {
	color: #04b6f5 !important;
}
.media svg,.media img {
	vertical-align: middle;
}
.media .media-body {
  flex: 1;
}
.media .media-body span {
  font-size: .9rem;
  line-height: 1.2;
  color: #6c757d;
}  
.media .ps-4 {
  padding-left: .8rem !important;
}
.media .align-self-center {
  align-self: center !important;
}
.media .mb-0 {
	margin-bottom: 0 !important;
}
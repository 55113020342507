$primary: #04B6F5;

.nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 60px;
    max-width: 576px;
    margin: 0 -16px;
    background-color: #fff;
    z-index: 99;
}

.nav-space {
    height: 60px;
    min-width: 100px;
}

.nav-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .nav-icon-solid {
        fill: #22222299;
    }
    
    .nav-icon-outline {
        stroke: #22222299;
    }

    & .nav-text {
        color: #22222299;
        font-size: .8rem;
    }

    & .nav-button__badge {
        position: absolute;
        right: 0;
        top: -5px;
        color: white;
        background-color: #FF3B30;
        border-radius: 100px;
        display: flex;
        height: 20px;
        width: 20px;
        font-size: 12px;
        justify-content: center;
        align-items: center;
    }

    &.active {
        .nav-icon-solid {
            fill: $primary;
        }
        
        .nav-icon-outline {
            stroke: $primary;
        }
    
        & .nav-text {
            color: $primary;
        }
    }
}
.dateBadge {
    align-self: center;
    background-color: #eee;
    color: #535353;
    border-radius: 16px;
    padding: 2px 8px;
    font-size: 14px;
    text-align: center;
}

.msgListWrap {
    overflow-y: scroll;
    padding-top: 2rem;
    flex-grow: 1;

    &::-webkit-scrollbar {
        display: none;
    }
}

.msgList {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 8px 0;
    gap: 16px;
}
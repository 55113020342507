.news-item {
    background: white;
    border-radius: 1rem;
    padding: 12px;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    cursor: pointer;

    & .news-item__title {
        font-size: 1rem;
        font-weight: 500;
		line-height: 1.25rem;
        margin-bottom: 8px;
    }

    & .news-item__description {
        font-size: .9rem;
		line-height: 1.2rem;
    }
	
    & .news-item__date {
        font-size: .9rem;
    }
	
    &:hover {
        background-color: #eee;
    }
}

.news-item__img-c {
    overflow: hidden;
    height: 90px;
    width: 90px;
    border-radius: 6px;
    background: center center no-repeat;
    background-size: cover;
    margin-right: 5px;

    & .news-item__img {
        height: 100%;
        width: 100%;
    }
}
.screen {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: #2B3A4C;
    z-index: 999;
    overflow: hidden;
}

.bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;

    & .callerSrc {
        height: 100vh;
        width: auto;

        &.dimmed {
            opacity: 0.6;
        }
    }
}

.controlIcon {
    cursor: pointer;
}

.controlText {
    color: white;
    max-width: min-content;
    text-align: center;
    font-weight: 300;
    user-select: none;

    &.bold {
        font-weight: 500;
    }
}

.contactWrap {
    height: 100px;
    width: 100px;
    border-radius: 9909px;
    overflow:  hidden;
    border: 2px solid white;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    & > img {
        width: 100%;
        height: auto;
    }
}

.youWrap {
    height: 135px;
    width: 100px;
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        height: auto;
        width: 100%;
    }
}

.notification {
    background: rgba(255, 255, 255, 0.55);
    backdrop-filter: blur(8px);
    border-radius: 100px;
    position: absolute;
    padding: 8px 16px;
    left: 50%;
    width: max-content;
    transform: translateX(-50%);
    bottom: 150%;
}
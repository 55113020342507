form.uk-search {width: 100%;}
.header-search-input {
    border: none;
    outline: none;
	min-width:85%;
}

.header-search-input::placeholder {
  font-weight: normal;
	color: #666;
}

.header-search-img {padding: 0 12px 0 0;}


form.uk-search .search_autocomplete div {
  background: #fff;
  padding: 4px 1em 4px .4em;
  border-bottom: 0; /*solid 1px #ccc;*/
  cursor: pointer;
}
form.uk-search .search_autocomplete div:hover {
  background: #eee;
  border: 1px solid #ccc;
}

form.uk-search .search_autocomplete {
    background: #fff;
    font-size: .85rem;
    line-height: 1.4;
    max-height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    width: calc(100% - 65px - 10px);
    z-index: 9999999;
    margin-top: -4px;
    margin-left: 27px;
    padding-top: 10px;  
  
}
form.uk-search .search_autocomplete:not(:empty) {
  border: 0px solid #eee;
}

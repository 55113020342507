#price_sale_1,#price_sale_2,#price_sale_3,#price_sale_4{
    margin: 15px 15px!important;
  }
  
  .pay_page .btn.btn-biryza {
      position: absolute;
      bottom: 0;
      margin: 0 0 15px 30px;
      padding: 0;
      width: calc(100% - 90px);
      height: 40px;
      line-height: 34px;
  }
  
  .price_sale_name {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
    padding-bottom: 5px;
    /*border-bottom: 4px solid #00baff;
    margin-left: 30px;
    margin-right: 30px;/**/
  }

  .price_sale_icon{
    background: url(../../../../assets/clock.svg);
    height: 30px;
    position: absolute;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 10px;
    top: 10px;
}
.price_selected .price_sale_icon{
  background: url(../../../../assets/clock_white.png);
  background-size: contain;
  background-repeat: no-repeat;
}
  
  .price_sale_price {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    /*margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 5px;/**/
  }
  
  .profile_left_menu_item i{
    width:25px;
    text-align: center;
  }
  
  .yook-close-payment-form{
    min-width:150px;
    margin: 20px auto;
  }
  
  .yook_pay_informer {
    text-align: center;
    margin-top: 40px;
    font-size: 22px;
    margin-bottom: 5px;
  }
  

  .tarif_page_accordion .accordion{
    background: #293a4b;
  }
  .tarif_page_accordion .accordion-item .accordion-header button.accordion-button{
    background: #293a4b;
    color:#fff;
    border:0;
    border-radius: 0;
  }
  .tarif_page_accordion .accordion-item{
    border: 0;
    border-top: solid 1px #ccc;
    border-radius: 0;
    margin: 0 10px;
  }
  .tarif_page_accordion .accordion-item .accordion-body{
    color:#fff;
    background: #293a4b;
  }
  .tarif_page_accordion .accordion-item .accordion-body *{
    color:#fff;
  }

  .tarif_page_accordion .accordion .accordion-item .accordion-header button.accordion-button.collapsed:focus {
    border: 0;
  }
.faceWrap {
    height: 35px;
    width: 35px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 100%;
        height: auto;
    }
}

$primary: #04B6F5;

.sendBtn {
    border: 0;
    padding: 0;
    background-color: $primary;
    transition: all .1s;
    opacity: 1;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    display: grid;
    place-items: center;

    &:disabled {
        opacity: 0.5;
    }
}

.messageInput {
    flex-grow: 1;
    border-radius: 10px;
    background-color: #eee;
    border: none;
    padding: 8px 12px;
    outline: none;
    box-shadow: none;
    transition: all .1s;
    width:200px;

    &:focus {
        box-shadow: 0 0 0 1px $primary;
        background: rgba(4, 182, 245, 0.08);
    }
}


.page {
    height: calc(100vh - 56px);
    width: 100vw;
    padding: 1rem;
    max-width: 576px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
}

@keyframes pulse {
    0% { transform: scale(1); background-color: #65c565; }
    10% { 
        transform: scale(1.25); 
        background-color: lighten($color: #65c565, $amount: 15%); 
    }
    0% { transform: scale(1); background-color: #65c565; }
}

.onlineText {
    color: #65c565;
    display: flex;
    align-items: center;
    gap: 5px;
    & > div {
        border-radius: 100px;
        height: 5px;
        width: 5px;
        background-color: #65c565;
        animation: pulse 4s infinite ease-in-out;
    }
}

@keyframes fadeInLoading {
    to { opacity: 1; }
}


.notfound-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    z-index: 9999;
    pointer-events: none;
    opacity: 1;
}
.notfound-wrapper .notfound-content {
    width: 10rem;
    height: 10rem;
}
.notfound-wrapper .notfound-content p {
    width: 100%;
	font-size: 1rem; 
	font-weight: 500;
	line-height: 1.6;
	color: #000;
}

.notfound-wrapper .notfound-content img {
    width: 100%;
    vertical-align: middle;
}
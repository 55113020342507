 .premium-page {
     background-color: #2B3A4C;
     min-height: 100vh;
     width: 100vw;
     max-width: 576px;
     margin: -1rem;
     padding: 1rem;
 }

 .premium-text {
    color: rgba(250, 250, 250, 0.67);
    font-weight: 300;
    letter-spacing: 0.5px;
 }

.premium-title {
    letter-spacing: 0.15px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
 }

 .features-list {
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-template-rows: 1fr 1fr;
     gap: 12px;

     & .feature-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        gap: 12px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 16px;

        & > span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            letter-spacing: 0.1px;
        }
     }
 }

 .feature-collapse-title {
    color: white;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    display: flex;
    justify-content: space-between;
    margin: 0 -1rem;
    padding: 1rem;
    transition: .2s;

    &.active {
        background: rgba(255, 255, 255, 0.1);
    }

    & .arrow {
        transform: rotate(0);
        transition: all .2s;

        &.active {
            transform: rotate(180deg);
        }
    }
 }

 .feature-collapse {
     color: white;
     transition: .2s;
     margin: 0 -1rem;
    padding: 0 1rem;
    padding-bottom: 1rem;
    background: transparent;

    &.collapsing, &.show {
        background: rgba(255, 255, 255, 0.1);
    }
 }
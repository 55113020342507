.case-card__description {
    min-width: max-content;
    font-weight: 300;
    z-index: 2;
}

.case-card {
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100px;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-width: max-content;
    cursor: pointer;
}

.case-card__title {
    z-index: 2;
}

.case-card__gradient {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: url('../../../../../../public/assets/cases/gradient.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 0.85;
    z-index: 0;
}
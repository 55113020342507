$primary: rgb(4, 182, 245);

.call-card {
    font-size: 1rem;
	line-height: 1.3;
    border-radius: 1rem;
    z-index: 0;
	
    & .call-card__block {
        
		margin-bottom: 9px;
	}

    & .call-card__title {
        font-size: 1rem;
		line-height: 1.3rem;
        margin-bottom: 0.4rem;
    }

    & .call-card__description {
        font-size: .95rem;
		line-height: 1.3rem;
        margin-bottom: 0.5rem;
    }
}

.call-card__image-c {
    position: relative;
    min-width: 80px;
    box-sizing: content-box;

    & > img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
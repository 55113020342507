.notification-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 1rem;
    z-index: 99999;
}

.notificaton {
    background-color: #181818;
    border-radius: 8px;
    padding: 14px 16px;
    min-width: fit-content;
}
.slide-up__wrap {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    &.dark {
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.slide-up {
    background-color: #fff;
    border-radius: 1rem 1rem 0 0;
    padding: 1rem;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-basis: 25vh;
    width: 100%;
    max-width: 576px;

    &.half {
        max-height: 60vh;
        min-height: 60vh;
        height: 60vh;
    }
}

.slide-up__content {
    overflow-y: scroll;
    flex-grow: 1;
    position: relative;
    margin-bottom: 100px;


    &::-webkit-scrollbar {
        width: 4px;
    }
}
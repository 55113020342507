.page-header {
    margin: -16px;
    padding: 15px 0;
    background-color: #FAFAFB; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
} 

.page-header-transparent{
    background-color: transparent; 
}

.page-header-transparent h5{
    color: #fff; 
}
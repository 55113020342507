.contactImgWrap {
    height: 48px;
    width: 48px;
    border-radius: 999px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 100%;
        height: auto;
    }
}

$primary: #04B6F5;

.unread {
    background-color: $primary;
    border-radius: 100px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
        color: white;
        font-weight: 500;
        font-size: 12px;
    }

    &.mute {
        background-color: rgba(34, 34, 34, 0.34);
    }
}

.noChats {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: 75vh;
}

.timeText {
    font-size: 14px;
    font-weight: 500;
    min-width: max-content;
}

.stickyTop {
    position: sticky;
    top: 0;
    background-color: #fafafb;
    padding: 16px;
    margin: -16px;
    margin-bottom: -8px;
}

.lastRoomMessage{
    overflow-wrap: break-word;
    max-width: 100%;
}
.category-card-line {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: black;
    text-decoration: none;
    padding: 15px 15px;
    border-radius: 1rem;
    background-color: #fff;

    &:hover {
        color: black;
        background-color: #eeeeee;
    }
}

.category-card-line__description {
    font-size: 0.9rem;
}
.category-card-line-body {
   padding-left: 7px;
   font-size: 1rem;
   line-height: 1.25rem;
   font-weight: 500;
}
.category-card-line-body p{
   margin-bottom: 0;
}
.back-btn {
    padding: 10px 20px;
}

.back-btn.solid {
    background-color: #fff;
    border-radius: 100px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}